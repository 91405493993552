import request from '@/utils/request';

export function getFuelRecord(params) {
  return request({
    url: '/fuel_record/data',
    methods: 'get',
    params
  });
}

export function ExportFuelRecord(params) {
  return request({
    url: '/fuel_record/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function getSiteDetail(params) {
  return request({
    url: '/site_detail/data',
    methods: 'get',
    params
  });
}

export function ExportSiteDetail(params) {
  return request({
    url: '/site_detail/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function getSiteSum(params) {
  return request({
    url: '/site_sum/data',
    methods: 'get',
    params
  });
}

export function ExportSiteSum(params) {
  return request({
    url: '/site_sum/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}

export function getHistoryPrice(params) {
  return request({
    url: '/history_price/data',
    methods: 'get',
    params
  });
}

export function ExportHistoryPrice(params) {
  return request({
    url: '/history_price/export',
    method: 'get',
    responseType: 'blob',
    params
  });
}
